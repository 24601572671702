import './index.css';
import {AmplifyProvider} from '@aws-amplify/ui-react'
import { NavBar,HeroLayout3,TermsPolicies,MarketingFooter} from './ui-components';
import '@aws-amplify/ui-react/styles.css'
import {studioTheme} from './ui-components'

export default function terms(){
    return (
        <AmplifyProvider theme ={studioTheme}>
        <NavBar width ='100%' />
        <HeroLayout3 width ='100%' />
        <TermsPolicies width ='90%' />
        <MarketingFooter width ='100%' />
        </AmplifyProvider>
    )
}