/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function HeroLayout1(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Smart and Easy to Manage Race Photos": {},
        "Get more done with Tamarind. Host your race photos, launch a seller page, send participants notifications and more, all in one place.":
          {},
        Message: {},
        Button31932681: {},
        Button31932689: {},
        "Action Buttons": {},
        HeroMessage: {},
        Left: {},
        image: {},
        Right: {},
        HeroLayout1: {},
      },
      variantValues: { mode: "Light" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  const buttonThreeOneNineThreeTwoSixEightOneOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/contact",
  });
  const buttonThreeOneNineThreeTwoSixEightNineOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/demo",
  });
  return (
    <Flex
      gap="0"
      width="1440px"
      height="500px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "HeroLayout1")}
    >
      <Flex
        gap="10px"
        direction="column"
        width="720px"
        justifyContent="center"
        alignItems="center"
        grow="1"
        basis="720px"
        alignSelf="stretch"
        overflow="hidden"
        position="relative"
        padding="120px 120px 120px 120px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "Left")}
      >
        <Flex
          gap="24px"
          direction="column"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          objectFit="cover"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "HeroMessage")}
        >
          <Flex
            gap="16px"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Message")}
          >
            <Text
              fontFamily="Inter"
              fontSize="36px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="50px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Smart and Easy to Manage Race Photos"
              {...getOverrideProps(
                overrides,
                "Smart and Easy to Manage Race Photos"
              )}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              shrink="0"
              alignSelf="stretch"
              objectFit="cover"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Get more done with Tamarind. Host your race photos, launch a seller page, send participants notifications and more, all in one place."
              {...getOverrideProps(
                overrides,
                "Get more done with Tamarind. Host your race photos, launch a seller page, send participants notifications and more, all in one place."
              )}
            ></Text>
          </Flex>
          <Flex
            padding="0px 0px 0px 0px"
            width="303px"
            height="46px"
            shrink="0"
            position="relative"
            {...getOverrideProps(overrides, "Action Buttons")}
          >
            <Button
              display="flex"
              gap="0"
              position="absolute"
              top="0px"
              left="157px"
              justifyContent="center"
              alignItems="center"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Get Started"
              onClick={() => {
                buttonThreeOneNineThreeTwoSixEightOneOnClick();
              }}
              {...getOverrideProps(overrides, "Button31932681")}
            ></Button>
            <Button
              display="flex"
              gap="0"
              position="absolute"
              top="0px"
              left="0px"
              justifyContent="center"
              alignItems="center"
              size="large"
              isDisabled={false}
              variation="primary"
              children="Try it Out"
              onClick={() => {
                buttonThreeOneNineThreeTwoSixEightNineOnClick();
              }}
              {...getOverrideProps(overrides, "Button31932689")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="10px"
        direction="column"
        width="720px"
        justifyContent="center"
        alignItems="center"
        grow="1"
        basis="720px"
        alignSelf="stretch"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Right")}
      >
        <Image
          width="720px"
          height="500px"
          grow="1"
          basis="500px"
          position="relative"
          padding="0px 0px 0px 0px"
          src="https://svgshare.com/i/k2B.svg"
          // src="tamarind_hero_image.svg"
          {...getOverrideProps(overrides, "image")}
        ></Image>
      </Flex>
    </Flex>
  );
}
