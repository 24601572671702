/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function MarketingFooter(props) {
  const { overrides, ...rest } = props;
  const termsAmpersandPoliciesOnClick = useNavigateAction({
    type: "url",
    url: "/terms",
  });
  const contactUsOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/contact",
  });
  const vectorThreeTwoZeroNineTwoNineEightSixOnClick = useNavigateAction({
    type: "url",
    url: ".",
  });
  return (
    <View
      width="1440px"
      height="190px"
      position="relative"
      padding="0px 0px 0px 0px"
      {...rest}
      {...getOverrideProps(overrides, "MarketingFooter")}
    >
      <View
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        {...getOverrideProps(overrides, "Rectangle 738")}
      ></View>
      <View
        padding="0px 0px 0px 0px"
        width="1369px"
        height="107px"
        position="absolute"
        top="57px"
        left="71px"
        {...getOverrideProps(overrides, "Content")}
      >
        <Flex
          gap="24px"
          position="absolute"
          top="0px"
          right="0px"
          alignItems="flex-start"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 405")}
        >
          <Flex
            gap="8px"
            direction="column"
            shrink="0"
            height="88px"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 403")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="272px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="About Us"
              {...getOverrideProps(overrides, "About Us")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="272px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Terms"
              onClick={() => {
                termsAmpersandPoliciesOnClick();
              }}
              {...getOverrideProps(overrides, "Terms & Policies")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(255,255,255,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="272px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Contact Us"
              onClick={() => {
                contactUsOnClick();
              }}
              {...getOverrideProps(overrides, "Contact Us")}
            ></Text>
          </Flex>
        </Flex>
        <View
          padding="0px 0px 0px 0px"
          width="136px"
          height="24px"
          position="absolute"
          top="83px"
          left="0px"
          {...getOverrideProps(overrides, "Logo")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="400"
            color="rgba(255,255,255,1)"
            lineHeight="24px"
            textAlign="right"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            position="absolute"
            bottom="0px"
            right="0px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="@2022, Tamarind"
            {...getOverrideProps(overrides, "@2022, Tamarind")}
          ></Text>
        </View>
      </View>
      <View
        width="40px"
        height="40px"
        position="absolute"
        top="64px"
        left="71px"
        overflow="hidden"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "logo_800px")}
      >
        <Icon
          width="40px"
          height="40px"
          viewBox={{ minX: 0, minY: 0, width: 40, height: 40 }}
          paths={[
            {
              d: "M32.5 0L7.5 0C3.35786 0 0 3.35786 0 7.5L0 32.5C0 36.6421 3.35786 40 7.5 40L32.5 40C36.6421 40 40 36.6421 40 32.5L40 7.5C40 3.35786 36.6421 0 32.5 0Z",
              fill: "rgba(207,109,38,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="0%"
          onClick={() => {
            vectorThreeTwoZeroNineTwoNineEightSixOnClick();
          }}
          {...getOverrideProps(overrides, "Vector32092986")}
        ></Icon>
        <Icon
          width="4.14990234375px"
          height="11.7001953125px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 4.14990234375,
            height: 11.7001953125,
          }}
          paths={[
            {
              d: "M11.7 0L0 0L0 4.15L11.7 4.15L11.7 0Z",
              fill: "rgba(250,250,250,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="76.5%"
          bottom="13.13%"
          left="44.75%"
          right="26%"
          transformOrigin="top left"
          transform="rotate(-90deg)"
          {...getOverrideProps(overrides, "Vector32092987")}
        ></Icon>
        <Icon
          width="19.2001953125px"
          height="4.10009765625px"
          viewBox={{
            minX: 0,
            minY: 0,
            width: 19.2001953125,
            height: 4.10009765625,
          }}
          paths={[
            {
              d: "M19.2 0L0 0L0 4.1L19.2 4.1L19.2 0Z",
              fill: "rgba(250,250,250,1)",
              fillRule: "nonzero",
            },
          ]}
          position="absolute"
          top="31.25%"
          bottom="58.5%"
          left="26%"
          right="26%"
          {...getOverrideProps(overrides, "Vector32092988")}
        ></Icon>
      </View>
    </View>
  );
}
