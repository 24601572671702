/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Button, Divider, Flex, Icon, Text, View } from "@aws-amplify/ui-react";
export default function MarketingPricing(props) {
  const { overrides, ...rest } = props;
  const buttonTwoNineSevenSixSixSixEightSixOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/contact",
  });
  const buttonTwoNineSevenSixSixSevenTwoZeroOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/contact",
  });
  const buttonTwoNineSevenSixSixSevenThreeSevenOnClick = useNavigateAction({
    target: "_blank",
    type: "url",
    url: "/contact",
  });
  return (
    <Flex
      gap="24px"
      width="1440px"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="24px 24px 24px 24px"
      backgroundColor="rgba(239,240,240,1)"
      {...rest}
      {...getOverrideProps(overrides, "MarketingPricing")}
    >
      <Flex
        gap="24px"
        width="1392px"
        alignItems="flex-start"
        grow="1"
        basis="1392px"
        height="439px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 63")}
      >
        <Flex
          gap="24px"
          direction="column"
          width="448px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="448px"
          height="439px"
          position="relative"
          padding="24px 24px 24px 24px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 390")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(79,79,79,1)"
            lineHeight="30px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Starter"
            {...getOverrideProps(overrides, "Starter")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Free"
            {...getOverrideProps(overrides, "Free")}
          ></Text>
          <Button
            display="flex"
            gap="0"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Get Started"
            onClick={() => {
              buttonTwoNineSevenSixSixSixEightSixOnClick();
            }}
            {...getOverrideProps(overrides, "Button29766686")}
          ></Button>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13829766688")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="400px"
              grow="1"
              basis="400px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Great for volunteer photographers to host and share photos of small community events"
              {...getOverrideProps(
                overrides,
                "Great for volunteer photographers to host and share photos of small community events"
              )}
            ></Text>
          </Flex>
          <Divider
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider29766687")}
          ></Divider>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13929766692")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon29766693")}
            >
              <Icon
                width="17.590087890625px"
                height="13.41015625px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 17.590087890625,
                  height: 13.41015625,
                }}
                paths={[
                  {
                    d: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
                    fill: "rgba(64,170,191,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="23.29%"
                bottom="20.83%"
                left="14.21%"
                right="12.5%"
                {...getOverrideProps(overrides, "Vector29766694")}
              ></Icon>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="360px"
              grow="1"
              basis="360px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Upto 2k Photos, Unlmiited Contributors, Launch Search Page"
              {...getOverrideProps(
                overrides,
                "Upto 2k Photos, Unlmiited Contributors, Launch Search Page"
              )}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            width="fit-content"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13729766696")}
          >
            <Text
              fontFamily="Inter"
              fontSize="22px"
              fontWeight="400"
              color="rgba(242,78,30,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0.07px"
              width="31px"
              height="19px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="X"
              {...getOverrideProps(overrides, "X")}
            ></Text>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="366px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="AI based Tagging, Custom Branding, Selling Photos, Email Marketing, Support"
              {...getOverrideProps(
                overrides,
                "AI based Tagging, Custom Branding, Selling Photos, Email Marketing, Support"
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="448px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="448px"
          height="439px"
          position="relative"
          padding="24px 24px 24px 24px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 392")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(79,79,79,1)"
            lineHeight="30px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Premium"
            {...getOverrideProps(overrides, "Premium")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="$99/Event"
            {...getOverrideProps(overrides, "$99/Event")}
          ></Text>
          <Button
            display="flex"
            gap="0"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Get Started"
            onClick={() => {
              buttonTwoNineSevenSixSixSevenTwoZeroOnClick();
            }}
            {...getOverrideProps(overrides, "Button29766720")}
          ></Button>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13829766722")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="400px"
              grow="1"
              basis="400px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Perfect plan if you are looking to sell race photos and notify participants"
              {...getOverrideProps(
                overrides,
                "Perfect plan if you are looking to sell race photos and notify participants"
              )}
            ></Text>
          </Flex>
          <Divider
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider29766721")}
          ></Divider>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13929766726")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon29766727")}
            >
              <Icon
                width="17.590087890625px"
                height="13.41015625px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 17.590087890625,
                  height: 13.41015625,
                }}
                paths={[
                  {
                    d: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
                    fill: "rgba(64,170,191,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="23.29%"
                bottom="20.83%"
                left="14.21%"
                right="12.5%"
                {...getOverrideProps(overrides, "Vector29766728")}
              ></Icon>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="360px"
              grow="1"
              basis="360px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="10k photos included, Unlimited Contributors, Launch Search Page."
              {...getOverrideProps(
                overrides,
                "10k photos included, Unlimited Contributors, Launch Search Page."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13729766730")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon29766731")}
            >
              <Icon
                width="17.590087890625px"
                height="13.41015625px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 17.590087890625,
                  height: 13.41015625,
                }}
                paths={[
                  {
                    d: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
                    fill: "rgba(64,170,191,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="23.29%"
                bottom="20.83%"
                left="14.21%"
                right="12.5%"
                {...getOverrideProps(overrides, "Vector29766732")}
              ></Icon>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="360px"
              grow="1"
              basis="360px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="AI based Tagging, Custom Brandingn, Sell Photos, Email Marketing, Premium Support."
              {...getOverrideProps(
                overrides,
                "AI based Tagging, Custom Brandingn, Sell Photos, Email Marketing, Premium Support."
              )}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="24px"
          direction="column"
          width="448px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="448px"
          height="439px"
          position="relative"
          padding="24px 24px 24px 24px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Frame 393")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="400"
            color="rgba(79,79,79,1)"
            lineHeight="30px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Enterprise"
            {...getOverrideProps(overrides, "Enterprise")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="20px"
            fontWeight="700"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="$499/Year"
            {...getOverrideProps(overrides, "$499/Year")}
          ></Text>
          <Button
            display="flex"
            gap="0"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            size="large"
            isDisabled={false}
            variation="primary"
            children="Get Started"
            onClick={() => {
              buttonTwoNineSevenSixSixSevenThreeSevenOnClick();
            }}
            {...getOverrideProps(overrides, "Button29766737")}
          ></Button>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13829766739")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="400px"
              grow="1"
              basis="400px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Ideal for teams managing multiple large-scale events in a calendar year."
              {...getOverrideProps(
                overrides,
                "Ideal for teams managing multiple large-scale events in a calendar year."
              )}
            ></Text>
          </Flex>
          <Divider
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider29766738")}
          ></Divider>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13929766743")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon29766744")}
            >
              <Icon
                width="17.590087890625px"
                height="13.41015625px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 17.590087890625,
                  height: 13.41015625,
                }}
                paths={[
                  {
                    d: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
                    fill: "rgba(64,170,191,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="23.29%"
                bottom="20.83%"
                left="14.21%"
                right="12.5%"
                {...getOverrideProps(overrides, "Vector29766745")}
              ></Icon>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="360px"
              grow="1"
              basis="360px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="100k photos included, Unlimited Events, Contributors, 5 Admins, Launch Search Page."
              {...getOverrideProps(
                overrides,
                "100k photos included, Unlimited Events, Contributors, 5 Admins, Launch Search Page."
              )}
            ></Text>
          </Flex>
          <Flex
            gap="16px"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 13729766747")}
          >
            <View
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Icon29766748")}
            >
              <Icon
                width="17.590087890625px"
                height="13.41015625px"
                viewBox={{
                  minX: 0,
                  minY: 0,
                  width: 17.590087890625,
                  height: 13.41015625,
                }}
                paths={[
                  {
                    d: "M5.59 10.58L1.42 6.41L0 7.82L5.59 13.41L17.59 1.41L16.18 0L5.59 10.58Z",
                    fill: "rgba(64,170,191,1)",
                    fillRule: "nonzero",
                  },
                ]}
                position="absolute"
                top="23.29%"
                bottom="20.83%"
                left="14.21%"
                right="12.5%"
                {...getOverrideProps(overrides, "Vector29766749")}
              ></Icon>
            </View>
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(79,79,79,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="360px"
              grow="1"
              basis="360px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="AI based Tagging, Custom Brandingn, Sell Photos, Email Marketing, Priority Support."
              {...getOverrideProps(
                overrides,
                "AI based Tagging, Custom Brandingn, Sell Photos, Email Marketing, Priority Support."
              )}
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
