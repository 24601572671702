import './index.css';
import {AmplifyProvider} from '@aws-amplify/ui-react'
import { NavBar,MarketingFooter} from './ui-components';
import '@aws-amplify/ui-react/styles.css'
import {studioTheme} from './ui-components'
import { useState } from 'react';
import { Storage} from 'aws-amplify';
import placeholder from './placeholder.svg';
import { Button, Heading, Text, Flex, Divider, Image} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
// import awsconfig from './aws-exports';
// import { Amplify, API } from 'aws-amplify';

// Amplify.configure(awsconfig);
// API.configure(awsconfig);

export default function Demo()
{
    const [fileData, setFileData] = useState();
    const [fileStatus, setFileStatus] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [display, setDisplay] = useState(placeholder);
    const [resulttext, setResulttext] = useState();
    const [result, setResult] = useState();

    
    const uploadFile = async() => {
        setLoadingStatus(true)
        const status = await Storage.put(`demoimage/${fileData.name}`, fileData, {contentType:fileData.Type});
        console.log(21, status);

        const imageurl = await Storage.get(`demoimage/${fileData.name}`);
        console.log(22, imageurl);
        setDisplay(imageurl);

        const response = await fetch('https://dy2bxb377vc2oonp27p7bpoeia0vcqdx.lambda-url.us-west-1.on.aws/');
        const data = await response.json();
        console.log(23, data);

        let data1 = data.map((element) => <li>{element}</li>);
        console.log(23, data1);
        if (data.length == 0){
            data1 = ["Sorry. No tags could be found for the uploaded image"];
        }
        setResult(data1);
        setResulttext("Identified BIB Numbers:")
        setLoadingStatus(false)
        };
    
    return (
        <AmplifyProvider theme ={studioTheme}>
        <NavBar width= "100%" />
        <Flex direction="row" justifyContent="space-around">
            <Flex direction="column" justifyContent="space-around">
                <Heading level={3}>Upload any race photo to get BIB numbers</Heading>
                <input type= "file" id= 'file1' name="file1" accept= ".jpeg" onChange={(e)=> { setFileData(e.target.files[0]); setFileStatus(true)}}/>
                <Button variation="primary" isDisabled={!fileStatus} isLoading={loadingStatus} loadingText="Finding tags in the image" onClick={uploadFile}> Upload File</Button>
                <Image src={display}  height="500px" width="500px" padding="1rem"/>
            </Flex>
            <Divider orientation="vertical" size = 'small'/>
            <Flex direction="column">
                <Heading level={3}>{resulttext}</Heading>
                <Text fontSize="xl" fontWeight="semibold" lineHeight="medium">{result}</Text>
            </Flex>
        </Flex>
        <MarketingFooter width ='100%' />
        </AmplifyProvider>
    )
}

