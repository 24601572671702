import {AmplifyProvider} from '@aws-amplify/ui-react'
import {NavBar, HeroLayout1, Features4x1,MarketingPricing,MarketingFooter} from './ui-components'
import '@aws-amplify/ui-react/styles.css'
import {studioTheme} from './ui-components'
import {ScrollView,Flex,View} from '@aws-amplify/ui-react';

function App() {
  return (
    <AmplifyProvider theme ={studioTheme}>
      <Flex direction="column" justifyContent="flex-start" alignItems="stretch" alignContent="flex-start" wrap="nowrap" gap="1rem">
        <View position='sticky' top='0'>
          <NavBar width="100%"/>
        </View>
        <View>
          <ScrollView height="870px">
            <HeroLayout1 width ='100%' padding="5rem"/>
            <Features4x1 id = "features" width ='100%' />
            <MarketingPricing id ="pricing" width ='100%' />
            <MarketingFooter width ='100%' bottom='0'/>
          </ScrollView>
        </View>
      </Flex>
    </AmplifyProvider>
  );
}

export default App;
