import './index.css';
import {AmplifyProvider} from '@aws-amplify/ui-react'
import { NavBar,MarketingFooter, Contact} from './ui-components';
import '@aws-amplify/ui-react/styles.css'
import {studioTheme} from './ui-components'
import { useState } from 'react';
import {Heading, Text, Flex, View, useTheme, Button, TextField,TextAreaField, Alert, Image, Divider} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import contact_info from './contact_info.svg';
import {Storage } from 'aws-amplify';

export default function ContactUs()
{
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [city, setCity] = useState("");
    const [message, setMessage] = useState("");
    const [submitstatus, setSubmitStatus] = useState("");
    const [alertstatus,setAlertStatus] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = { first_name : name, email_id : email, place: city, mail: message}
        console.log(23,data)
        const status = Storage.put(`contactus/${name.replace(/ /g,"_")}.json`, data ,{contentType:"plain/json"});
        console.log(25, status);
        setSubmitStatus("success")
        setAlertStatus("Your request is submitted. We will reach out to you soon.")
        };

    const { tokens } = useTheme();
    
    return (
        <AmplifyProvider theme ={studioTheme}>
        <NavBar width= "100%" />
        <View padding="1rem" margin="1rem">
            <Heading level={2}>Ready to get started with Tamarind?</Heading>
            <Text level={2}>A member of our team would love to introduce you to our product.</Text>
            <Flex direction="row" width = "70%" justifyContent="space-around">
                <Flex direction="column" justifyContent="space-around">
                    <Image src={contact_info} padding="1rem"/>
                </Flex>
                <Divider orientation="vertical" size = 'small'/>
                <Flex as="form" direction="column" gap={tokens.space.medium} width="20rem" onSubmit={handleSubmit}>
                    <TextField placeholder="First Name" id="first_name" onChange={(e) => setName(e.target.value)}/>
                    <TextField placeholder="Your Email*" id="emai_id" isRequired={true} onChange={(e) => setEmail(e.target.value)}/>
                    <TextField placeholder="City" id="city" onChange={(e) => setCity(e.target.value)}/>
                    <TextAreaField label="How can we help you?" id="message" resize="vertical" placeholder="Your message..." onChange={(e) => setMessage(e.target.value)}/>
                    <Button type="submit">Submit</Button>
                    <Alert variation= {submitstatus} isDismissible={false} hasIcon={true} heading={alertstatus}></Alert>
                </Flex>
            </Flex>
        </View>
        <MarketingFooter width ='100%' />
        </AmplifyProvider>
    )
}

